@import "bootstrap4"
rupture.scale = 0 575px 767px 991px 1200px 1600px
rupture.anti-overlap = 1px

/*Variables---*/
$white = #ffffff
$navy = #002D58
$blue = #0054A4
$gold = #D7AD41
$darkgold = #B17F00
$corvette = #F2D699
$medium-grey = #4f565d
$dark-grey = #343a40

$font-stack = 'Montserrat', helvetica, arial, sans-serif
$font-stack-headers = 'Montserrat', helvetica, arial, sans-serif

/*Mixins*/
$transition-all( $time = .3, $type = ease-in-out )
    transition all unit($time, 's') $type
    
background-img($x-position, $y-position)
    background-repeat no-repeat
    background-position $x-position $y-position
    background-size cover
    
headline($color, $size, $weight, $line)
    color $color
    font-family $font-stack-headers
    font-size $size
    font-weight $weight
    line-height $line

objectfit($obj-width, $obj-height, $obj-pos)
    object-fit cover
    object-position $obj-pos
    height $obj-height
    width  $obj-width
    

.show-mobile
    display none
    white-space nowrap
    +below(380px)
        display initial
        
.hide-mobile
    +below(380px)
        display none

 
.headroom 
    /*transition transform 200ms linear*/
    background-color transparent
    transition all 200ms linear
    z-index 1;
    position fixed
    width 100%

.headroom--pinned 
    -moz-transform translateY(0%)
    -webkit-transform translateY(0%)
    -o-transform translateY(0%)
    -ms-transform translateY(0%)
    transform translateY(0%)

.headroom--unpinned 
    -moz-transform translateY(-100%)
    -webkit-transform translateY(-100%)
    -o-transform translateY(-100%)
    -ms-transform translateY(-100%)
    transform translateY(-100%)
    
.headroom--top
    background-color #fff
    
/*Stops Flash of Unstyled Text (requires google fonts loader)*/
p, li, ol, a, h1, h2, h3, h4, h5, h6, dd, dl, dt, td, strong, address, label, button, blockquote, caption, figcaption, legend, input::placeholder
    opacity 0
    $transition-all(.2s) 
.wf-active 
    p, li, ol, a, h1, h2, h3, h4, h5, h6, dd, dl, dt, td, strong, address, label, button, blockquote, caption, figcaption, legend, input::placeholder
        opacity 1 !important
        $transition-all(.2) 
    
/*Site Styles---------------------------------*/
main
    padding 90px 0
    section 
        padding 30px 0
        
a
    transition-all()
    &:hover
        text-decoration none
        
a.btn,
input[type="submit"]
    background $gold
    color $navy
    padding .5em 1em
    border-radius 0
    font-weight 700
    $transition-all()
    &:hover
        background $navy
        color $gold
        cursor pointer
        $transition-all()
        
.btn-parent a
    @extends a.btn
        
section 
    padding 80px 0

/* Headings*/
for num in 1..6
    h{num}
        font-family $font-stack-headers
        font-weight 700 !important
body
    font-family 'Montserrat'

    h1
        font-size responsive 1.77rem 2.77rem
        font-range 575px 1199px
    h2
        font-size responsive 1.5rem 2.5rem
        font-range 575px 1199px
    h3
        font-size responsive 1.5rem 2rem
        font-range 575px 1199px
    h4
        font-size responsive 1.2rem 1.5rem
        font-range 575px 1199px
    h5
        font-size 1.25rem
    h6
        font-size 1rem

    p
        font-family $font-stack
        font-size responsive 1rem 1.13rem
        font-range 575px 992px
        line-height 1.65

    a
        font-family $font-stack
        $transtion-all()
        &:hover, &:focus
            $transtion-all()

.cover-img
    overflow hidden
    max-height 450px
    padding 0
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack center
    justify-content center
    -ms-flex-direction column
    flex-direction column

.info-text
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-pack center
    justify-content center
    -ms-flex-direction column
    flex-direction column
    .aligner 
        width 80%
    .align-item
        padding 15px
        width 80%
        &.transparent
            background-color hsla(360, 100%, 100%, 0.85)

    
// 
//  HEADER ---
// 
.headroom 
    background rgba($blue, .9)
    border-bottom 8px solid $gold
    a
        color $white
        &:not([href])
            color $white
            cursor pointer
            &:hover
                color $white
    .top-area
        color $white
        margin-bottom .5em
    .main-menu
        list-style-type none
        padding-left 0
        li
            display inline-block
            color $white
            
    +below(991px)
        .navbar-collapse
            width 325px
            max-width 100%
        .dropdown-menu.show
            background transparent
            padding 0
            border 0
            a
                color $white
    +below(415px)
        .navbar-collapse
            width 100%
    
.navbar-collapse
    -ms-flex-direction column
    flex-direction column
.top-nav
    margin-left auto
    #menu-top-nav, #menu-top-nav-1
        display -ms-flexbox
        display flex
        -ms-flex-direction row
        flex-direction row
        margin-left auto
        list-style none
        padding-left 0
        margin-bottom 10px
        li 
            a 
                color hsla(360, 100%, 100%, 0.5)
                padding .5rem
                &:hover, &:focus
                    color hsla(360, 100%, 100%, 1)
.navbar-collapse
    -ms-flex-align end
    align-items flex-end

.navbar-toggler-line
    display block
    width 24px
    height 2px
    background $white
    margin 6px 0
.top-area
    padding-left 0
    margin-bottom 0
    li
        list-style-type none
        display inline-block
        &.divider
            padding-left .5em
            padding-right .5em
        a
            transition-all()
            &:hover
                color $gold
                transition-all()
.bottom-area
    // display none
    opacity 1
.main-menu
    margin-bottom 0
    li
        padding-left .5em
        padding-right .5em
        &:last-of-type
            padding-right 0
        a
            transition-all()
            &:hover
                color $gold
                transition-all()
        
    
+below(991px)
    .container
        position relative
    .navbar-collapse
        position absolute
        right 0
        top 82px
        background rgba($navy, 95%)
        padding 1em
        .top-area
            padding-top 0
            margin-bottom .75em
            border-bottom 1px solid rgba($gold, .9)
            li
                display block
                a
                    display block
                    padding-bottom .25em
                &.divider
                    display none
                &:first-of-type
                    padding-top .25em
                &:last-of-type
                    margin-bottom .5em
        .main-menu
            li
                display block
                padding 0
                a
                    display block
                    margin .25em 0
                &.divider
                    display none
        
+below(576px)
    .navbar-brand
        width 200px
        img
            max-width 100%
    .navbar-collapse
        top 59px 
            
+below(415px)
    .navbar-collapse
        width 100%

//
//  HOMEPAGE ---
// 
.home
    .jumbotron
        position relative
        background-img(0, 90%)
        height 590px
        border-radius 0
        margin-bottom 0
        .sticker
            position absolute
            bottom 0
            right 25%
            background rgba($gold, 95%)
            border-radius 100%
            color $white
            line-height 1.2
            font-weight bold
            font-size 32px
            text-align center
            transform translateY(50%)
            height 200px
            width 200px
            display flex
            align-items center
            justify-content center            
            
    .intro
        height 460px
        background-image linear-gradient(90deg, #fff 45%, transparent), url('../../img/homepage/jermz.jpg')
        background-repeat no-repeat
        background-position-x right
        border-bottom 8px solid $gold
        .about-blurb
            color $navy
        +below(475px)
            background-size cover
    
    .team
        background $blue
        padding-bottom 100px
        h2
            margin-bottom 50px
            color $white
        .row
            justify-content center
        .team-member
            margin-bottom 25px
            max-width 375px
            img
                border-bottom 10px solid $darkgold
                max-width 100%
            .details
                padding 15px
                background $white
                color $navy
            .title
                color $darkgold
                font-size 24px
                font-weight 700
            .history
                list-style-type none
                padding-left 0
            li
                font-weight 300
                font-size 18px
                strong
                    font-weight bold
    
    .bill
        display flex
        align-items center
        max-height 615px
        background-image linear-gradient(to left, #fff 60%, transparent), url('../../img/homepage/bill.jpg')
        background-position left center
        background-repeat no-repeat
        color $navy
        .text-link,
        .click-call
            font-weight bold
            color $darkgold
            $transition-all()
            &:hover
                color $navy
                $transition-all()
            
    .contact
        background $blue
        h2
            color $white
            margin-bottom 25px
        label
            color $white
            font-weight 700
            width 100%
            max-width 100%
            margin-bottom 1em
        input[type="text"],
        input[type="email"],
        input[type="tel"],
        textarea
            padding .5em 1em
            max-width 100%
        .your-message
            width 100%
        textarea
            width 100%
        input[type="submit"]
            font-size responsive 16px 20px
            margin auto
            
    +below(991px)
        .jumbotron
            height 450px
            display flex
            align-items center
            figure
                transform translateY(50px)
            .sticker
                font-size 20px
                width 150px
                height 150px 
                right 10%
                transform translateY(25%)
                
        .intro
            height unset
            background-image linear-gradient(90deg, rgba(#fff,.9), rgba(#fff, .9)), url('../../img/homepage/jermz.jpg')
            .void
                display none
            .about-blurb
                flex 0 0 100%
                max-width unset
        
        .bill
            height unset
            background-image linear-gradient(90deg, rgba($white, .9), rgba($white, .9)), url('../../img/homepage/bill.jpg')
            .void
                display none
            .blurb
                flex 0 0 100%
                max-width unset
        
        .contact
            input[type="text"],
            input[type="email"],
            input[type="tel"]
                width 100%
    
    +below(768px)
        .privacy
            margin-top 1em
        
        .footer-top .footer-one
            font-size 18px
    
    +below(575px)
        .jumbotron
            .sticker
                transform translateY(50%)
                font-size 16px
                height 100px
                width 100px
    
    +below(415px)
        .contact
            padding-top 50px
            padding-bottom 40px
            h2
                margin-bottom 25px
        
        .footer-top .footer-one
            font-size 16px
            

.main-callout
    height 522px
    width 100%
    display -ms-flexbox
    display flex
    -ms-flex-align center
    align-items center
    -ms-flex-direction column
    flex-direction column
    -ms-flex-pack center
    justify-content center
    figure
        padding 15px
        max-width 720px
        h1
            font-size responsive 32px 48px
            color $white
        p 
            text-align center
            font-size 1.5rem
        


/*Icon Fonts*/
@font-face
    font-family 'icomoon'
    src url('/wp-content/themes/metro-theme/build/css/fonts/icomoon.eot?6d9quv')
    src url('/wp-content/themes/metro-theme/build/css/fonts/icomoon.eot?6d9quv#iefix') format('embedded-opentype'),
    url('/wp-content/themes/metro-theme/build/css/fonts/icomoon.ttf?6d9quv') format('truetype'),
    url('/wp-content/themes/metro-theme/build/css/fonts/icomoon.woff?6d9quv') format('woff'),
    url('/wp-content/themes/metro-theme/build/css/fonts/icomoon.svg?6d9quv#icomoon') format('svg')
    font-weight normal
    font-style normal

[class^="icon-"], [class*=" icon-"]
    font-family 'icomoon' !important
    speak none
    font-style normal
    font-weight normal
    font-variant normal
    text-transform none
    line-height 1
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

.icon-youtube
    &:before
        content "\e900"

.icon-down-arrow
    &:before
        content "\e901"

.icon-facebook
    &:before
        content "\e902"

.icon-instagram
    &:before
        content "\e903"

.icon-linkedin
    &:before
        content "\e904"

.icon-play-arrow
    &:before
        content "\e905"


.icon-play-container
    height 70px
    width 93px
    display table
    margin auto
    font-size 4rem
    text-align center
    &:link
        transition-all()
    &:hover, &:focus
        text-decoration none
        transition-all()
    &:before
        content "\e906"

.icon-search
    &:before
        content "\e907"

.icon-twitter
    &:before
        content "\e909" 
        
/*Navigation*/
.dropdown-menu
    .dropdown-item
        color $navy
        &.active,
        &:hover,
        &:focus
            background rgba($gold, .8)

.navbar-nav li:last-child .dropdown-menu
    right 0
    left auto
.navbar-toggler
    border 0
    &:hover, &:focus
        outline 0
.search-box
    display -ms-inline-flexbox
    display inline-flex
    .search-form
        margin-right 10px

                    
/*Sidebars-----------------------*/

/*Secondary---------------------------------*/
body
    .jumbotron
        border-radius 0
        
.secondary
    .jumbotron
        height 450px
        background-image url('http://via.placeholder.com/1920x450')
        background-img(top, center)

section.main-title.no-jumbotron
    padding 180px 0 0 0



// 
//  FOOTER ---
// 
footer
    position relative
    background-color $white
    a
        color $white
        transition-all()
        &:hover, &:focus
            text-decoration none
            color $white
            transition-all()
    .footer-top
        background-color $corvette
        padding 30px 0
        .footer-menu
            float right
            font-size 1.2rem
            margin-top 155px
            ul 
                list-style-type none
                padding-left 0
                li 
                    display inline-block
                    margin-left 30px
                    a 
                        color $dark-grey
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
        .click-call
            font-weight bold
            color $navy
        .footer-one
            color $navy
            font-size 22px
            overflow hidden
            width 100%
            a.click-call
                color $navy
                $transition-all()
                &:hover
                    color $white
                    $transition-all()
            ul
                color $white
                .company
                    font-weight 700
                font-size 1.125rem
                float left
                list-style-type none
                padding 0 0 10px 0
                a 
                    font-weight 700
                    color $dark-grey
                    transition-all()
                    &:hover, &:focus
                        color $blue
                        transition-all()
            .btn
                float left
                font-size 0.75rem
                clear both
            address
                margin-bottom unset
    .footer-bottom
        background-color $navy
        color $white
        padding 12px 0
        p
            font-size 1.125
            margin-bottom 0
        .social-container
            display -ms-flexbox
            display flex
            -ms-flex-direction row
            flex-direction row
            -ms-flex-align end
            align-items flex-end
            -ms-flex-pack flex-end
            justify-content flex-end
            .social
                list-style-type none
                margin-bottom 0
                padding-left 0
                li
                    float left
                    margin 0 0 0 10px
                    a 
                        font-size 1.75rem
                        transition-all()
                        &:hover, &:focus
                            color $blue
                            transition-all()
                ul
                    padding-top 55px
        .metro
            color white
        .links
            padding-top 15px
            ul 
                float right
            p
                float left
            li
                float left
                margin 0 0 0 25px
            a
                font-size 1rem
        .copyright
            margin 8px 0 0 0
            font-size 1rem
        .privacy
            font-weight bold
    .multi-line-divder-footer
        hr
            margin 2px 0 0 0
            &.line-1
                border-bottom 4px solid $dark-grey

            &.line-2
                border-bottom 2px solid $dark-grey

    .metro-credit
        background-image url(/wp-content/themes/metro-theme/img/metro.png)
        width 16px
        height 16px
        display inline-block
        margin-right -3px

/*Home Page*/                
        
/*secondary*/
section.main-title
    padding 40px 0
    
.secondary
    .sidebar
        ul 
            list-style-type none
            padding-left 15px
            border-left 1px solid #ced4da
            li 
                border-top 1px solid #ced4da
                &:first-child
                    border-top 0
                a
                    padding 15px 0
                    display block
                    transition-all() 
                    &:focus, &:hover
                        transition-all() 

.secondary-page
    .banner
        position relative
        padding 150px 0 75px
        +below(415px)
            padding 100px 0 25px
        background-color rgba($gold, .7)
        background-image unset
        &::before
            content ''
            display block
            position absolute
            top 0
            left 0
            height 100%
            width 100%
            background-image url('/wp-content/themes/metro-theme/img/germ-cloud.jpg')
            background-size cover
            z-index -1
        .title
            font-weight bold
            color $navy
    main
        padding 25px 0
    article
        p a
            font-weight bold
            color $gold
        +above(415px)
            padding-top 50px
            padding-bottom 50px
        li
            @extends body p
            
    aside
        border-left 1px solid $navy
        ul
            padding-left 0
            list-style-type none
            margin-bottom 0
            li
                border-bottom 1px solid $navy
                &:last-of-type
                    border-bottom 0
                &.current-menu-item
                    a
                        color $gold
        a
            color $blue
            font-weight bold
            display inline-block
            padding .5em
            
    .contact
        @extends .home .contact
        
.hospital-and-clinic-locations
    article
        address
            padding 15px
            margin-bottom 0
            &:nth-child(odd)
                background #f9f9f9
        .description
            padding 15px
            margin-top 2rem
            margin-bottom 0
            border-top 2px solid $navy
            border-bottom 2px solid $navy
            font-weight bold
            color $navy



.page-template-page-plain
    .main
        padding-top 80px
        padding-bottom 80px
        color $navy

    

/*Responsive-------------------------*/

@media (max-width: 576px)
    .home 
        .jumbotron
            height 350px
            .main-callout
                height 360px
    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            width 90%
            // h1
            //     font-size 2.5rem
            p
                font-size 1rem
    .cover-img
        max-height 300px
    .secondary 
        .jumbotron
            height 300px
        .main
            padding 30px 0
                
@media (max-width: 767px)
    .info-text
        padding 30px 15px
    .secondary
        article
            margin-bottom 30px
        .sidebar
            ul 
                border-left 0
                padding-left 0
        .jumbotron
            margin-bottom 0
    section.main-title.no-jumbotron
        padding 100px 0 0 0
    footer    
        .footer-bottom
            .social-container
                -ms-flex-align start
                align-items flex-start
                -ms-flex-pack flex-start
                justify-content flex-start
    

@media (min-width: 577px) and (max-width: 767px)
    
    .multi-callout
        .card
            margin-bottom 100px
    .main-callout 
        figure
            width 80%
    .cover-img
        max-height 375px
    .secondary 
        .jumbotron
            height 375px
    
            
@media (min-width: 768px) and (max-width: 991px)
    
    
    .top-nav
        margin-left 0
        #menu-top-nav-1
            -ms-flex-direction column
            flex-direction column
            li 
                a 
                    padding 0.5rem 1rem 0.5rem 0
                    display block
        
@media (min-width: 992px) and (max-width: 1199px)
    .placeholder
        border 1px

@media (max-width: 991px)
    header
        .navbar
            padding-top 15px
        .bottom-area
            display block
            .widget_search
                display none
            .widget_nav_menu
                display block

        .top-area
            padding-top 15px
            .widget_nav_menu
                display none
        .top-nav
            margin-left 0
            #menu-top-nav-1
                flex-direction column
                li 
                    a 
                        padding 0.5rem 1rem 0.5rem 0
                        display block