.breadcrumb
    padding 0.75rem 1rem
    margin-bottom 1rem
    list-style none
    background-color #e9ecef
    border-radius 0.25rem
    &::after
        display block
        clear both
        content ""

.breadcrumb-item
    float left
    & + .breadcrumb-item
        &::before
            display inline-block
            padding-right 0.5rem
            padding-left 0.5rem
            color $secondary
            content "/"
        &:hover
            &::before
                text-decoration underline
                text-decoration none
    &.active
        color $secondary