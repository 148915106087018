.btn-info
    color $white
    background-color $info
    border-color $info
    &:hover
        color $white
        background-color $infoBtnHov
        border-color $infoHov

.btn-info:focus, .btn-info.focus
    box-shadow 0 0 0 3px rgba(23, 162, 184, 0.5)

.btn-info.disabled, .btn-info:disabled
    background-color $info
    border-color $info

.btn-info:active, .btn-info.active,
.show > .btn-info.dropdown-toggle
    background-color $infoBtnHov
    background-image none
    border-color $infoHov
