.btn-outline-primary
    color $primary
    background-color transparent
    background-image none
    border-color $primary
    &:hover
        color $white
        background-color $primary
        border-color $primary

.btn-outline-primary:focus, .btn-outline-primary.focus
    box-shadow 0 0 0 3px rgba(0, 123, 255, 0.5)

.btn-outline-primary.disabled, .btn-outline-primary:disabled
    color $primary
    background-color transparent

.btn-outline-primary:active, .btn-outline-primary.active,
.show > .btn-outline-primary.dropdown-toggle
    color $white
    background-color $primary
    border-color $primary
