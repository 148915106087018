.btn-outline-warning
    color $warning
    background-color transparent
    background-image none
    border-color $warning
    &:hover
        color $white
        background-color $warning
        border-color $warning

.btn-outline-warning:focus, .btn-outline-warning.focus
    box-shadow 0 0 0 3px rgba(255, 193, 7, 0.5)

.btn-outline-warning.disabled, .btn-outline-warning:disabled
    color $warning
    background-color transparent

.btn-outline-warning:active, .btn-outline-warning.active,
.show > .btn-outline-warning.dropdown-toggle
    color $white
    background-color $warning
    border-color $warning
