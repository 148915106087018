.dropup,
.dropdown
    position relative

.dropdown-toggle
    &::after
        display inline-block
        width 0
        height 0
        margin-left 0.255em
        vertical-align 0.255em
        content ""
        border-top 0.3em solid
        border-right 0.3em solid transparent
        border-left 0.3em solid transparent
    &:empty
        &::after
            margin-left 0

.dropup
    .dropdown-menu
        margin-top 0
        margin-bottom 0.125rem
    .dropdown-toggle
        &::after
            border-top 0
            border-bottom 0.3em solid

.dropdown-menu
    position absolute
    top 100%
    left 0
    z-index 1000
    display none
    float left
    min-width 10rem
    padding 0.5rem 0
    margin 0.125rem 0 0
    font-size 1rem
    color #212529
    text-align left
    list-style none
    background-color $white
    background-clip padding-box
    border 1px solid rgba(0, 0, 0, 0.15)
    border-radius 0.25rem
    &.show
        display block

.dropdown-divider
    height 0
    margin 0.5rem 0
    overflow hidden
    border-top 1px solid #e9ecef

.dropdown-item
    display block
    width 100%
    padding 0.25rem 1.5rem
    clear both
    font-weight $font-weight-normal
    color #212529
    text-align inherit
    white-space nowrap
    background none
    border 0

.dropdown-item:focus, .dropdown-item:hover
    color #16181b
    text-decoration none
    background-color $light

.dropdown-item.active, .dropdown-item:active
    color $white
    text-decoration none
    background-color $primary

.dropdown-item.disabled, .dropdown-item:disabled
    color $secondary
    background-color transparent

.dropdown-header
    display block
    padding 0.5rem 1.5rem
    margin-bottom 0
    font-size 0.875rem
    color $secondary
    white-space nowrap