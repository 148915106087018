.pagination
    display -ms-flexbox
    display flex
    padding-left 0
    list-style none
    border-radius 0.25rem

.page-item
    &:first-child
        .page-link
            margin-left 0
            border-top-left-radius 0.25rem
            border-bottom-left-radius 0.25rem
    &:last-child
        .page-link
            border-top-right-radius 0.25rem
            border-bottom-right-radius 0.25rem
    &.active
        .page-link
            z-index 2
            color $white
            background-color $primary
            border-color $primary
    &.disabled
        .page-link
            color $secondary
            pointer-events none
            background-color $white
            border-color #ddd

.page-link
    position relative
    display block
    padding 0.5rem 0.75rem
    margin-left -1px
    line-height 1.25
    color $primary
    background-color $white
    border 1px solid #ddd

.page-link:focus, .page-link:hover
    color #0056b3
    text-decoration none
    background-color #e9ecef
    border-color #ddd

.pagination-lg
    .page-link
        padding 0.75rem 1.5rem
        font-size 1.25rem
        line-height 1.5
    .page-item
        &:first-child
            .page-link
                border-top-left-radius 0.3rem
                border-bottom-left-radius 0.3rem
        &:last-child
            .page-link
                border-top-right-radius 0.3rem
                border-bottom-right-radius 0.3rem

.pagination-sm
    .page-link
        padding 0.25rem 0.5rem
        font-size 0.875rem
        line-height 1.5
    .page-item
        &:first-child
            .page-link
                border-top-left-radius 0.2rem
                border-bottom-left-radius 0.2rem
        &:last-child
            .page-link
                border-top-right-radius 0.2rem
                border-bottom-right-radius 0.2rem