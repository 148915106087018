.btn-outline-success
    color $success
    background-color transparent
    background-image none
    border-color $success
    &:hover
        color $white
        background-color $success
        border-color $success

.btn-outline-success:focus, .btn-outline-success.focus
    box-shadow 0 0 0 3px rgba(40, 167, 69, 0.5)

.btn-outline-success.disabled, .btn-outline-success:disabled
    color $success
    background-color transparent

.btn-outline-success:active, .btn-outline-success.active,
.show > .btn-outline-success.dropdown-toggle
    color $white
    background-color $success
    border-color $success
