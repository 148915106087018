.btn-group,
.btn-group-vertical
    position relative
    display -ms-inline-flexbox
    display inline-flex
    vertical-align middle

.btn-group > .btn,
.btn-group-vertical > .btn
    position relative
    -ms-flex 0 1 auto
    flex 0 1 auto
    margin-bottom 0

.btn-group > .btn:hover,
.btn-group-vertical > .btn:hover
    z-index 2

.btn-group > .btn:focus, .btn-group > .btn:active, .btn-group > .btn.active,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active
    z-index 2

.btn-group .btn + .btn,
.btn-group .btn + .btn-group,
.btn-group .btn-group + .btn,
.btn-group .btn-group + .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn + .btn-group,
.btn-group-vertical .btn-group + .btn,
.btn-group-vertical .btn-group + .btn-group
    margin-left -1px

.btn-toolbar
    display -ms-flexbox
    display flex
    -ms-flex-wrap wrap
    flex-wrap wrap
    -ms-flex-pack start
    justify-content flex-start
    .input-group
        width auto

.btn-group
    & > .btn
        &:not(:first-child):not(:last-child):not(.dropdown-toggle)
            border-radius 0
        &:first-child
            margin-left 0
            &:not(:last-child):not(.dropdown-toggle)
                border-top-right-radius 0
                border-bottom-right-radius 0
    & > .btn-group
        float left
        &:not(:first-child):not(:last-child)
            & > .btn
                border-radius 0
        &:last-child
            &:not(:first-child)
                & > .btn
                    &:first-child
                        border-top-left-radius 0
                        border-bottom-left-radius 0

.btn-group > .btn:last-child:not(:first-child),
.btn-group > .dropdown-toggle:not(:first-child)
    border-top-left-radius 0
    border-bottom-left-radius 0

.btn-group > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group > .btn-group:first-child:not(:last-child) > .dropdown-toggle
    border-top-right-radius 0
    border-bottom-right-radius 0

.btn
    & + .dropdown-toggle-split
        padding-right 0.5625rem
        padding-left 0.5625rem
        &::after
            margin-left 0

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split
    padding-right 0.375rem
    padding-left 0.375rem

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split
    padding-right 0.75rem
    padding-left 0.75rem

.btn-group-vertical
    display -ms-inline-flexbox
    display inline-flex
    -ms-flex-direction column
    flex-direction column
    -ms-flex-align start
    align-items flex-start
    -ms-flex-pack center
    justify-content center
    & > .btn
        &:not(:first-child):not(:last-child)
            border-radius 0
        &:first-child
            &:not(:last-child)
                border-bottom-right-radius 0
                border-bottom-left-radius 0
        &:last-child
            &:not(:first-child)
                border-top-left-radius 0
                border-top-right-radius 0
    & > .btn-group
        &:not(:first-child):not(:last-child)
            & > .btn
                border-radius 0
        &:last-child
            &:not(:first-child)
                & > .btn
                    &:first-child
                        border-top-left-radius 0
                        border-top-right-radius 0

.btn-group-vertical .btn,
.btn-group-vertical .btn-group
    width 100%

.btn-group-vertical > .btn + .btn,
.btn-group-vertical > .btn + .btn-group,
.btn-group-vertical > .btn-group + .btn,
.btn-group-vertical > .btn-group + .btn-group
    margin-top -1px
    margin-left 0

.btn-group-vertical > .btn-group:first-child:not(:last-child) > .btn:last-child,
.btn-group-vertical > .btn-group:first-child:not(:last-child) > .dropdown-toggle
    border-bottom-right-radius 0
    border-bottom-left-radius 0