.btn-danger
    color $white
    background-color $danger
    border-color $danger
    &:hover
        color $white
        background-color $dangerBtnHov
        border-color $dangerHov

.btn-danger:focus, .btn-danger.focus
    box-shadow 0 0 0 3px rgba(220, 53, 69, 0.5)

.btn-danger.disabled, .btn-danger:disabled
    background-color $danger
    border-color $danger

.btn-danger:active, .btn-danger.active,
.show > .btn-danger.dropdown-toggle
    background-color $dangerBtnHov
    background-image none
    border-color $dangerHov
