@keyframes progress-bar-stripes 
  from 
    background-position: 1rem 0;
  
  to 
    background-position: 0 0;

.progress
    display -ms-flexbox
    display flex
    overflow hidden
    font-size 0.75rem
    line-height 1rem
    text-align center
    background-color #e9ecef
    border-radius 0.25rem

.progress-bar
    height 1rem
    line-height 1rem
    color $white
    background-color $primary
    transition width 0.6s ease

.progress-bar-striped
    background-image linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent)
    background-size 1rem 1rem

.progress-bar-animated
    -webkit-animation progress-bar-stripes 1s linear infinite
    animation progress-bar-stripes 1s linear infinite